<template>
  <div v-if="table_permissions" class="custom-content-height">
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      :height="getvh(56)"
      show-select
      :single-select="disableSelectAllCheckbox"
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="id.id"
      class="mx-2 mt-0 font-weight-bold"
      item-class="headerZindex"
      hide-default-footer
      @dblclick:row="showDetail"
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <!--    no-data end-->
      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-1"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>

          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <!--begin::Button-->
            <!--            <div-->
            <!--              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"-->
            <!--              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"-->
            <!--              v-if="permissionChecker('export')"-->
            <!--            >-->
            <!--              <button-->
            <!--                @click="$refs.exportSidebar.$data.exportSidebar = true"-->
            <!--                class="btn btn&#45;&#45;export-filter"-->
            <!--              >-->
            <!--                <span class="svg-icon">-->
            <!--                  <v-icon size="18">mdi-export-variant</v-icon>-->
            <!--                </span>-->
            <!--                Export-->
            <!--              </button>-->
            <!--            </div>-->
            <!--end::Button-->

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="$refs.filterSidebar.$data.filterSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>
          </div>
        </div>
        <!-- chart start -->
        <Statistics :item="statistics"></Statistics>
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->

        <!--        <hr />-->
        <Others ref="otherData"></Others>
      </template>
      <!-- column -->
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>

      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.id="{ item, value }">
        <span class="first-text" :id="value.id">
          <v-tooltip bottom v-if="item.id.detail.merged_order">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span>{{ value.id }}</span>
                <v-icon>mdi-file</v-icon>
              </div>
            </template>
            <span>Merged with Order:{{ item.id.detail.merged_order }}</span>
          </v-tooltip>
          <div v-else>{{ value.id }}</div>
        </span>
      </template>

      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissionChecker('add_call')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.add_call"
                :class="
                  !item.id.detail.actions.add_call ? 'text-muted' : 'text-dark'
                "
                @click="() => actionCall(item)"
                text
                >Add Call</v-btn
              >
            </div>
            <div v-if="permissionChecker('view')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.show"
                :class="
                  !item.id.detail.actions.show ? 'text-muted' : 'text-dark'
                "
                @click="showItemDetail(item)"
                text
                >View</v-btn
              >
            </div>
          </v-card>
        </v-menu>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <OrderCall
      ref="orderCall"
      :call_statuses="call_statuses"
      :page-loader="pageLoad"
      :refresher="updateTableContent"
    ></OrderCall>
  </div>
</template>

<script>
import {
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/callSheet.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import Others from "@/own/components/fulfillment/orders/Others.vue";
// import ClientColumn from "@/own/components/datatable/ClientColumn.vue";
// import OrderStatus from "@/own/components/datatable/OrderStatus.vue";

import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";

import Pagination from "@/own/components/pagination/Pagination.vue";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ApiService from "@/core/services/api.service";
import OrderCall from "@/own/components/customerService/callSheet/OrderCall";
import Statistics from "@/own/components/customerService/callSheet/Statistics";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: {
    Statistics,
    OrderCall,
    DatatableDetail,
    FilterSidebar,
    Pagination,
    Others,
    // ClientColumn,
    // OrderStatus,
    ComponentSelector,
  },
  props: {
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
    offset: true,
    columnsModal: false,
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
    this.$store.dispatch(UPDATE_TABLE_DATA, {}).then(() => {});
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getCALLSHEETTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getCALLSHEETTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getCALLSHEETTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getCALLSHEETTableData.last_page;
    },
    //filters
    filters: function () {
      return this.$store.getters.getCALLSHEETTableFilters;
    },

    table_permissions: function () {
      if (this.$store.getters.getCALLSHEETTablePermissions) {
        return this.$store.getters.getCALLSHEETTablePermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
    table_properties: function () {
      return this.$store.getters.getCALLSHEETTableProperties;
    },
    itemPerPage: function () {
      return this.$store.getters.getCALLSHEETTableState.per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getCALLSHEETTableState.sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getCALLSHEETTableState
          );
        } else {
          this.$store.commit(SET_TABLE_SORTBY, "");
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getCALLSHEETTableState
          );
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (this.$store.getters.getCALLSHEETTableState.sortOrder === "asc") {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getCALLSHEETTableState
        );
      },
    },
    headers: function () {
      /**
       * @type {[
          {
              value: string,
              text: string,
              type: string,
              sortable: string,
              exportable: string,
              visible: string,
              mobile_visible: string,
              align: string,
              itemClass: string,
              width: string,
              class: string,
              export_order: integer,
            }
                ]}
        */
      let headers = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.$store.getters.getCALLSHEETTableHeaders) {
          headers = this.$store.getters.getCALLSHEETTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getCALLSHEETTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        }
      } else {
        if (this.$store.getters.getCALLSHEETTableHeaders) {
          headers = this.$store.getters.getCALLSHEETTableHeaders.filter(
            (col) => col.visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getCALLSHEETTableHeaders.filter(
            (col) => col.visible === true
          );
        }
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "aging_day")
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    exportHeaders: function () {
      try {
        return this.$store.getters.getCALLSHEETTablePermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      } catch (error) {
        return null;
      }
    },
    types: function () {
      return [
        { name: "shipping label", value: "label" },
        { name: "barcode", value: "barcode" },
        { name: "invoice", value: "invoice" },
        { name: "manifest", value: "manifest" },
        { name: "packing summary", value: "packing_summary" },
        { name: "packing slip", value: "packing_slip" },
      ];
    },
    items: function () {
      let items = null;
      if (this.$store.getters.getCALLSHEETTableData) {
        items = this.$store.getters.getCALLSHEETTableData.data;
      }
      return items;
    },
    call_statuses() {
      return this.$store.getters.getCALLSHEETStatuses;
    },
    statistics() {
      return this.$store.getters.getCALLSHEETstatistics;
    },
  },
  methods: {
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getItemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getItemPerPage * this.getpageNumber - this.getItemPerPage;
          innerItems = this.items.slice(startFrom, this.getItemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    actionReopen(id) {
      this.pageLoad(true);
      let data = { id: id };
      ApiService.post("/fulfillment/orders/reopen", data)
        .then(() => {
          Swal.fire("Modified!", "Order modified!", "success");
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    actionCall(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { order_id: item.id.id };
      ApiService.post("customer_service/call_sheets/show", data)
        .then((response) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          // this.$refs.orderCall.$props.item = item;
          // this.$refs.orderCall.$props.other = response.data.detail;
          this.$refs.orderCall.setData(item, response.data.detail);
          this.$nextTick(() => {
            this.$refs.orderCall.toggleModal();
          });
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    // assignMultipleOrders() {
    //   if (this.selected.length > 0) {
    //     const orders_to_assign = this.selected;
    //     this.$refs.orderCall.$props.item = [orders_to_assign];
    //     this.$refs.orderCall.toggleModal();
    //   } else {
    //     Swal.fire({
    //       title: "Warning",
    //       text: `Select at least one order to assign!`,
    //       icon: "warning",
    //       showConfirmButton: false,
    //       timer: 2500,
    //     });
    //   }
    // },
    // pagination
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      // console.log("setting value to filter -->", val);
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = { ...this.$store.getters.getCALLSHEETTableState };
      // console.log("state", state);
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      // this.$refs.filterSidebar.$refs.filter.clearFiltersState();
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getCALLSHEETTableState)
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    // filters end
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    loadDetailData(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { order_id: id };
      ApiService.post("customer_service/call_sheets/show", data)
        .then((response) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          // this.$refs.otherData.$props.isLoaded = true;
          // this.$refs.otherData.$props.other = response.data.detail;
          this.$refs.otherData.setData(response.data.detail);
          this.$refs.otherData.toggleModal();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    showItemDetail(item) {
      this.loadDetailData(item.id.id);
    },
    // eslint-disable-next-line no-unused-vars
    showDetail(val, { item, isMobile = true }) {
      this.actionCall(item);
    },
    isAnyFilterSelected() {
      let isSelected = false;
      if (this.$store.getters.getCALLSHEETTableState.filters.length > 0) {
        isSelected = true;
      }
      return isSelected;
    },
    // exports
    // downloadItem(columns) {
    //   if (this.isAnyFilterSelected()) {
    //     this.$store.commit(SET_PAGE_LOADING, true);
    //     // console.log("order table state", this.$store.getters.getCALLSHEETTableState);
    //     this.$store
    //       .dispatch(EXPORT_TABLE_DATA, {
    //         columns: columns,
    //         filters: this.$store.getters.getCALLSHEETTableState.filters,
    //       })
    //       .then(() => {
    //         const link = document.createElement("a");
    //         link.href = this.$store.getters.getCALLSHEETExportUrl;
    //         link.download = "Export.csv";
    //         link.click();
    //         setTimeout(() => {
    //           this.$store.commit(SET_PAGE_LOADING, false);
    //         }, 1500);
    //         this.$refs.exportSidebar.$refs.export.toggleModal();
    //         link.remove();
    //       });
    //   } else {
    //     Swal.fire({
    //       title: "Warning",
    //       text: "Please, select at least one filter!",
    //       icon: "warning",
    //       showConfirmButton: false,
    //       timer: 2500,
    //     });
    //   }
    // },
    // exports end
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          ApiService.post(`/authorizations/clients/${item.id}/destroy`)
            .then(() => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    toggleColumnsModal() {
      this.columnsModal = !this.columnsModal;
    },

    showUploadModal() {
      this.$refs.upload.toggleUploadModal();
    },
    // showExportModal() {
    //   if (this.isAnyFilterSelected()) {
    //     this.$refs.exportSidebar.$refs.export.toggleModal();
    //   } else {
    //     Swal.fire({
    //       title: "Warning",
    //       text: "Please, select at least one filter!",
    //       icon: "warning",
    //       showConfirmButton: false,
    //       timer: 2500,
    //     });
    //   }
    // },
    async updateTableContent() {
      await this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getCALLSHEETTableState)
        .then(() => {});

      this.$forceUpdate();
      this.selected = [];
    },
  },
};
</script>
